<template>
    <el-table v-if="tableData.length > 0" :data="tableData" class="w-full">
        <template slot="append">
            <p class="ml-24 pl-3 py-2 text-lg">
                {{ intToMoneyFormat(totalAmount) }}
            </p>
        </template>
        <el-table-column label="Nr" prop="custom_nr" width="100" />
        <el-table-column :label="$t('economy.amount')">
            <template slot-scope="scope">
                {{ intToMoneyFormat(scope.row.total_netto) }}
            </template>
        </el-table-column>
        <el-table-column :label="$t('common.status')" width="100">
            <template slot-scope="scope">
                {{ $t(`economy.${scope.row.status}`) }}
            </template>
        </el-table-column>
        <el-table-column :label="$t('economy.issue_at')" prop="issue_at" width="130" align="center" />
        <el-table-column :label="$t('economy.due_date')" prop="due_date_at" width="130" align="center" />
        <el-table-column width="100">
            <template slot-scope="scope">
                <el-button-group class="flex">
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="left">
                        <router-link :to="`invoices/${scope.row.id}`">
                            <el-button type="primary" size="mini">
                                <fa-icon :icon="['fas', 'info']" />
                            </el-button>
                        </router-link>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.print')" placement="left">
                        <el-button type="primary" size="mini" @click="handlePrint(scope.row.id)">
                            <fa-icon :icon="['fas', 'print']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.download')" placement="left">
                        <el-button type="primary" size="mini" @click="handleDownload(scope.row.id)">
                            <fa-icon :icon="['fas', 'download']" />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>

export default {
    props: {
        tableData: {
            type:    Array,
            default: () => [],
        },
    },

    computed: {
        totalAmount() {
            return this.tableData.reduce((prev, cur) => prev + cur.total_netto, 0);
        },
    },

    methods: {
        handlePrint(id) {
            this.$emit('print', { id, download: 0 });
        },

        handleDownload(id) {
            this.$emit('print', { id, download: 1 });
        },
    },
};
</script>
